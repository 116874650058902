<template>
    <button
        :class="{ 'icon-button': true, [`icon-button_${size}`]: true, [`icon-button_${theme}`]: true, 'icon-button_disabled': disabled }"
        v-on="$listeners"
    >
        <slot />
    </button>
</template>

<script>
    export default {
        name: 'IconButton',
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            theme: {
                type: String,
                default: 'primary',
                validator: value => ['info', 'primary', 'primary-outlined', 'secondary', 'success'].includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;

        svg {
            width: 55%;
            fill: #edf2ff;
            pointer-events: none;
            transition: fill 0.3s;
        }

        &_disabled {
            pointer-events: none;
            opacity: 0.65;
        }

        &_primary {
            background-color: #3760cb;

            &:hover {
                background-color: #557ce2;
            }
        }

        &_primary-outlined {
            border: 0.2rem solid #3760cb;
            background-color: #1e284b;
            transition: border-color 0.3s, background-color 0.3s;

            &:hover {
                background-color: #293666;
                border-color: #557ce2;
            }
        }

        &_secondary {
            background-color: #202738;

            svg {
                fill: #a9aebd;
            }

            ::v-deep .loader {
                border-color: #a9aebd;
            }

            &:hover {
                background-color: #262c41;

                svg {
                    fill: #ccd1de;
                }

                ::v-deep .loader {
                    border-color: #ccd1de;
                }
            }
        }

        &_success {
            background-color: #4ab990;

            &:hover {
                background-color: #68cfb0;
            }
        }

        &_info {
            background-color: #6bd4f5;

            svg {
                fill: #38445a;
            }

            ::v-deep .loader {
                border-color: #38445a;
            }

            &:hover {
                background-color: #9de8ff;

                svg {
                    fill: #59749c;
                }

                ::v-deep .loader {
                    border-color: #59749c;
                }
            }
        }

        &_medium {
            width: 4.8rem;
            height: 4.8rem;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }

        &_small {
            width: 3.6rem;
            height: 3.6rem;
        }
    }

    @media screen and (min-width: 120em) {
        .icon-button {
            &_medium {
                width: 5.6rem;
                height: 5.6rem;
            }

            &_small {
                width: 4.4rem;
                height: 4.4rem;
            }
        }
    }
</style>
